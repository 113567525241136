import "./LoadingStart.css";

const LoadingStart = ()=>{
    return(
        <div className="loading_start">
            <div className="loading-bar">
                <div className="load-roll-cover">
                    <div className="load-roll-content">

                    </div>
                </div>
            </div>
            <div className="loading-txt blink-1">
                Loading ...
            </div>
        </div>
    );
}

export default LoadingStart;