import MoneyCurrent from "../../utils/MoneyCurrent/MoneyCurrent";
import UserHead from "../../utils/UserHead/UserHead";
import Guide from "../../assets/images/hard/guide.png";
import Airdrop from "../../assets/images/hard/airdrop.png";
import Board from "../../assets/images/hard/Board.png";
import BG1 from "../../assets/images/bg-1.jpg";
import PlayGame from "../../assets/images/hard/btn-play.png";
import TurnGame from "../../assets/images/hard/turn-game.png";
import "./HomeDragonKing.css";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useCallback, useEffect, useState } from "react";
import GuidePop from "../../utils/Guide/GuidePop";
import PopupSuccess from "../../utils/PopupSuccess/PopupSuccess";
import LoadingInApp from "../../utils/Loading/LoadingInApp";

const HomeDragonKing = ()=>{
    const { user } = useUser();
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    const [userInfo, setUserInfo] = useState(null);
    const [openGuide, setOpenGuide] = useState(false);
    const [popTurn, setPopTurn] = useState(false);
    const [background, setBackground] = useState(BG1);
    const [level, setLevel] = useState(1);
    const [loading, setLoading] = useState(true);
    const [currentTurn, setCurrentTurn] = useState(6);
    const [isHaveTurn, setIsHaveTurn] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    
    const getUserInfo = useCallback(async (userId) => {
        try {
            const response = await fetch(`${baseUrl}/mc-user/v1/get-user-info-and-level`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setUserInfo(data.user);
            setBackground(data.background);
            setLevel(data.level);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
          } else {
            console.error('Error fetching user:', data.message);
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const resetTurnGame = useCallback(async (userId) => {
        try {
            const response = await fetch(`${baseUrl}/game/v1/reset-turn-game`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setIsHaveTurn(true);
            setCurrentTurn(6);
          } else {
            console.error('Error fetching user:', data.message);
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const getTurnGame = useCallback(async (userId) => {
        try {
            const response = await fetch(`${baseUrl}/game/v1/calc-turn-game`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            if(data.turn_game > 0){
                setIsHaveTurn(true);
            }
            if(data.time_status == false){
                setTimeLeft(data.time_remaining * 60);
                const timer = setInterval(() => {
                    setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        resetTurnGame(userId);
                        return 0;
                    }
                    return prevTime - 1;
                    });
                }, 1000);
            
                return () => clearInterval(timer);
            }
            setCurrentTurn(data.turn_game);
          } else {
            console.error('Error fetching user');
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const handlePopGuide = ()=>{
        setOpenGuide(true);
    }
    const closeGuide = ()=>{
        setOpenGuide(false);
    }
    
    useEffect(() => {
        if (user) {
            getUserInfo(user.id);
            getTurnGame(user.id);
        }
    }, [user, getUserInfo]);

    const handleTurnGame = ()=>{
        setPopTurn(true);
    }
    const closePopTurn = ()=>{
        setPopTurn(false);
    }
  
    // Chuyển đổi giây thành phút và giây để hiển thị
    const formatTime = (time: number) => {
      const mins = Math.floor(time / 60);
      const secs = time % 60;
      return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };


    if (loading) {
        return (
            <LoadingInApp />
        );
    }
    return(
        <div className="home_dragon_king">
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            <div className="home-bg">
                <img src={background} alt="Background" />
            </div>
            <div className="content-in-home">
                <div className="home_dragon_king_contain">
                    <div className="mc-container-app">
                        <UserHead userInfo={userInfo} level={level} />
                        <div className="mc-space">
                            <MoneyCurrent userInfo={userInfo} />
                        </div>
                    </div>
                    <button type="button" className="btn-main-screen guide-btn" onClick={handlePopGuide}>
                        <img src={Guide} alt="Guide" />
                    </button>
                    <Link to="/airdrop" className="btn-main-screen airdrop-btn">
                        <img src={Airdrop} alt="Airdrop" />
                    </Link>
                    <Link to="/rank" className="btn-main-screen board-btn" >
                        <img src={Board} alt="Board" />
                    </Link>
                    {
                        userInfo && userInfo.turn_game > 0 ? (
                        <Link to="/game" className="btn-play-game" >
                            <img src={PlayGame} alt="Play Game" />
                        </Link>
                        ) :
                        (
                            <button type="button" className="btn-play-game" onClick={handleTurnGame} >
                                <img src={PlayGame} alt="Play Game" />
                            </button>
                        )
                    }
                    
                    {
                        isHaveTurn ? (
                            <Link to="/game" className="btn-turn-game" >
                                <div className="turn-game-cover">
                                    <img src={TurnGame} alt="Turn Game" />
                                    <div className="txt">
                                        <span>Turn </span><span>{currentTurn ? currentTurn : "Loading..."}</span><span>/</span><span>{userInfo ? userInfo.max_turn_game : "Loading..."}</span>
                                    </div>
                                </div>
                                
                            </Link>
                        ) :
                        (
                            <button type="button" className="btn-turn-game" onClick={handleTurnGame} >
                                <div className="turn-game-cover">
                                    <div className="time-mantain">
                                        {formatTime(timeLeft)}
                                    </div>
                                    <img src={TurnGame} alt="Turn Game" />
                                    <div className="txt">
                                        <span>Turn </span><span>{userInfo ? userInfo.turn_game : "Loading..."}</span><span>/</span><span>{userInfo ? userInfo.max_turn_game : "Loading..."}</span>
                                    </div>
                                </div>
                            </button>
                        )
                    }
                    
                </div>
            </div>
            {
                openGuide && <GuidePop closeGuide={closeGuide} />
            }
            {
                popTurn && <PopupSuccess textOnPop={'You have run out of turns, please wait! Turns will be restored after 15 minutes !'} closeSuccess={closePopTurn} />
            }
        </div>
    );
}

export default HomeDragonKing;