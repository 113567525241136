import dong from "../../assets/images/hard/dong.png";
import { useCallback, useState } from "react";
import { formatNumber } from "../../utils/FormatNumber/FormatNumber";
import PopupBuyCard from "../../utils/Portal/PopupBuyCard";
import NoticeNoMoney from "../../utils/Portal/NoticeNoMoney";
import NoticeBuyCardDone from "../../utils/Portal/NoticeBuyCardDone";

const BuyCard = (props)=>{

    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    const [isPopupOpen, setPopupOpen] = useState(false);
    const closePopup = () => setPopupOpen(false);
    const [isPopupOpenNoMoney, setPopupOpenNoMoney] = useState(false);
    const closePopupNoMoney = () => {
        setPopupOpenNoMoney(false);
    }
    const [isPopupOpenDone, setPopupOpenDone] = useState(false);
    const closePopupDone = () => {
        setPopupOpenDone(false);
    }

    const buyCard = useCallback(async (userId, cardID) => {
        try {
            const response = await fetch(`${baseUrl}/cards/v1/buy-card`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                    'card_id' : cardID
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            if(data.is_money == true){
                props.onFetchData({
                    "rank_num": data.rank_num,
                    "rank_txt": data.rank_txt,
                });
                closePopup(); // Đóng popup sau khi xử lý
                setPopupOpenDone(true);
                props.onBalance(data.money_after);
            }else{
                closePopup(); // Đóng popup sau khi xử lý
                setPopupOpenNoMoney(true);
            }
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey, props]);

    const handlerShowPopupBuy = ()=>{
        setPopupOpen(true)
    }

    const handleConfirm = () => {
        buyCard(props.userID, props.cardID);
    };

    return(
        <>
            <button className="buy-btn" onClick={handlerShowPopupBuy}>
                <div className="price">
                    <span>Buy with</span>
                    <span> {formatNumber(props.priceCard)}</span>
                </div>
                <img src={dong} alt="coin" />
            </button>
            <PopupBuyCard isOpen={isPopupOpen} 
                        onClose={closePopup} 
                        onConfirm={handleConfirm} >
                Are you sure you want to buy this card for {props.priceCard} ? 
            </PopupBuyCard>
            <NoticeNoMoney isOpen={isPopupOpenNoMoney} 
                        onClose={closePopupNoMoney}  >
                You don't have enough money!
            </NoticeNoMoney>
            <NoticeBuyCardDone isOpen={isPopupOpenDone} 
                        onClose={closePopupDone} >
                You have purchased successfully!
            </NoticeBuyCardDone>
            
        </>
        
    );
}

export default BuyCard;