import React from "react";
import ReactDOM from "react-dom";

const PopupBuyCard = ({ isOpen, onClose, onConfirm, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div style={styles.container}>
      <div style={styles.backdrop} onClick={onClose}></div>
      <div style={styles.content}>
        {children}
        <div style={styles.buttonGroup}>
          <button style={styles.button} onClick={onClose}>No</button>
          <button style={{ ...styles.button, ...styles.confirmButton }} onClick={onConfirm}>Yes</button>
        </div>
      </div>
    </div>,
    document.getElementById("pop-buy-card")
  );
};

const styles = {
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    position: "relative",
    zIndex: 1001,
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    background: "#ccc",
    color: "#000",
  },
  confirmButton: {
    background: "#4CAF50",
    color: "#fff",
  },
};

export default PopupBuyCard;