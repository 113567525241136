import "./LoadingInApp.css";
import LoadingGift from "../../assets/images/hard/loading-gif.gif";

const LoadingInApp = ()=>{
    return(
        <div className="loading_in_app">
            <div className="loading_in_app_img">
                <img src={LoadingGift} alt="LoadingGift" />
            </div>
        </div>
    );
}

export default LoadingInApp;