import React from "react";
import ReactDOM from "react-dom";
import "./UpgradeCardPop.css";


const UpgradeCardPop = ({ isOpen, onClose, onConfirm, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
        <div className="upgrade_card">
            <div className="upgrade_card_contain">
                <div className="chirldren-up">
                    {children}
                </div>
                <div className="btn-up">
                    <button type="button" onClick={onConfirm}>Upgrade</button>
                </div>
                <div className="btn-close-up">
                    <button type="button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
        <div className="overlay-upgrade">

        </div>
    </>
    ,
    document.getElementById("pop-upgrade-card")
  );
};

export default UpgradeCardPop;