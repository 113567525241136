import "./Friend.css";
import IconInv from "../../assets/images/hard/infre.png";
import IconCoin from "../../assets/images/hard/coin.png";
import BtnFriend from "../../assets/images/hard/button-add-friend.png";
import { useUser } from "../../context/UserContext";
import { useCallback, useEffect, useState } from "react";
import LoadingInApp from "../../utils/Loading/LoadingInApp";

const Friend = ()=>{
    const { user } = useUser();
    const [listFriends, setListFriends] = useState([]);
    const [isHaveFriend, setIsHaveFriend] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [numberFriends, setNumberFriends] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const getListFriends = useCallback(async (userId) => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
            const response = await fetch(`${baseUrl}/mc-friends/v1/list-friends`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_tele_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            if(data.list_friends.length > 0){
                setListFriends(data.list_friends);
            }else{
                setIsHaveFriend(true);
            }
            setNumberFriends(data.count);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
          } else {
            setIsHaveFriend(true);
            setNumberFriends(data.count);
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, []);
    const handleGetLink = () => {
        const link = `https://t.me/DrakinApp_bot/Drakin?startapp=${user.user_id}`;
        navigator.clipboard
        .writeText(link)
        .then(() => {
            setIsCopy(true);
        })
        .catch((err) => {
            console.error("Không thể copy link:", err);
        });
    };

    useEffect(() => {
        if (user) {
            getListFriends(user.user_id)
        }
    }, [user, getListFriends]);

    if (isLoading) {
        return (
            <LoadingInApp />
        );
    }

    return(
        <div className="invite-friend">
            <div className="invite-friend-contain">
                <div className="mc-container-app">
                    <div className="title">
                        Invite friends
                    </div>
                    <div className="desc">
                        You and your friend will get bonuses
                    </div>
                    <div className="in-frie">
                        <div className="icon">
                            <img src={IconCoin} alt="Airdrop" />
                        </div>
                        <div className="txt">
                            Invite friends
                        </div>
                        <div className="get-coin">
                            Get 20.000 for each friend (Max: 10K Friends)
                        </div>
                        <button type="button" className="btn-add-friend" onClick={handleGetLink}>
                            <img src={BtnFriend} alt="Airdrop" />
                        </button>
                        {
                            isCopy && (
                            <div className="txt-copy">
                                Invitation link copied !
                            </div>
                            )
                        }
                    </div>
                    <div className="list-added-fri">
                        <div className="laf-title">
                            List of your friends ({numberFriends})
                        </div>
                        <div className="list">
                            {
                                listFriends.length > 0 && listFriends.map((item, index)=>(
                                <div key={index} className="item">
                                    <div className="img-fri">
                                        <img src={IconInv} alt="Airdrop" />
                                    </div>
                                    <div className="name-friend">
                                        {item.invitee_username}
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                        {
                            isHaveFriend && 
                            (
                            <div className="no-friends">
                                You have not invited any friends yet.
                            </div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Friend;