import upgrade from "../../assets/images/hard/upgrade.png";
import UpgradeCardPop from "../../utils/Portal/UpgradeCardPop";
import Upgrade from "../../assets/images/hard/upgrade.png";
import dong from "../../assets/images/hard/dong.png";
import ArrowUpGrade from "../../assets/images/hard/up.png";
import { useCallback, useState } from "react";
import { formatNumber } from "../../utils/FormatNumber/FormatNumber";
import NoticeNoMoney from "../../utils/Portal/NoticeNoMoney";
import NoticeBuyCardDone from "../../utils/Portal/NoticeBuyCardDone";

const BtnUpGradeCard = (props)=>{
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const closePopup = () => {
        setIsPopupOpen(false);
    }
    const [isPopupOpenNoMoney, setPopupOpenNoMoney] = useState(false);
    const closePopupNoMoney = () => {
        setPopupOpenNoMoney(false);
    }
    const [isPopupOpenDone, setPopupOpenDone] = useState(false);
    const closePopupDone = () => {
        setPopupOpenDone(false);
    }
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;

    const upgradeCard = useCallback(async (userId, cardID) => {
        try {
            const response = await fetch(`${baseUrl}/cards/v1/upgrade-card`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                    'card_id' : cardID
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            if(data.is_money == true){
                props.onFetchData({
                    "rank_num": data.rank_num,
                    "rank_txt": data.rank_txt,
                });
                closePopup();
                setPopupOpenDone(true);
                props.onBalance(data.money_after);
            }else{
                closePopup();
                setPopupOpenNoMoney(true);
            }
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
        
    }, [baseUrl, appKey, props]);

    const handleConfirm = () => {
        upgradeCard(props.userID, props.infoCard.id);
    };

    const handlerOpenPopUpGrade = () => {
        setIsPopupOpen(true);
    };

    return(
        <>
        {
            props.infoCard.next_level_earn > 0 ? (
                <>
                    <button className="buy-btn" onClick={handlerOpenPopUpGrade}>
                        <img src={upgrade} alt="coin" />
                        <div className="price">
                            <span>Upgrade</span>
                        </div>
                    </button>
                    <UpgradeCardPop isOpen={isPopupOpen} 
                                onClose={closePopup} 
                                onConfirm={handleConfirm} >
                            <div className="dr-on-card">
                                <img src={props.infoCard.image_card} alt="Dragon" />
                            </div>
                            <div className="title">
                                <div className="img-up">
                                    <img src={Upgrade} alt="Upgrade" />
                                </div>
                                <div className="txt">
                                Upgrade
                                </div>
                            </div>
                            <div className="desc">
                                Increase the coin collection disance
                            </div>
                            <div className="level-contain">
                                <div className="item">
                                    <div className="item-contain">
                                        <div className="level">
                                            <span>LEVEL </span><span>{props.infoCard.current_level}</span>
                                        </div>
                                        <div className="mc-money">
                                            <div className="mc-img-dong">
                                                <img src={dong} alt="dong" />
                                            </div>
                                            <div className="mc-mon">
                                                +{formatNumber(props.infoCard.money_current_earn)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="arrow-up">
                                    <img src={ArrowUpGrade} alt="Arrow Upg" />
                                </div>
                                <div className="item">
                                    <div className="item-contain">
                                        <div className="level">
                                            <span>LEVEL </span><span>{Number(props.infoCard.current_level) + 1}</span>
                                        </div>
                                        <div className="mc-money">
                                            <div className="mc-img-dong">
                                                <img src={dong} alt="dong" />
                                            </div>
                                            <div className="mc-mon">
                                                +{formatNumber(props.infoCard.next_level_earn)}
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="exchange">
                                <div className="ex-item ex-item-contain-color">
                                    <div className="ex-item-contain">
                                        <div className="ex-txt">
                                            Exchange coins
                                        </div>
                                        <div className="mc-money">
                                            <div className="mc-img-dong">
                                                <img src={dong} alt="dong" />
                                            </div>
                                            <div className="mc-mon">
                                                - {formatNumber(props.infoCard.amount_to_upgrade)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                    </UpgradeCardPop>
                    <NoticeNoMoney isOpen={isPopupOpenNoMoney} 
                                onClose={closePopupNoMoney}  >
                        You don't have enough money!
                    </NoticeNoMoney>
                    <NoticeBuyCardDone isOpen={isPopupOpenDone} 
                                onClose={closePopupDone} >
                        You have upgraded successfully!
                    </NoticeBuyCardDone>
                </>
            ) : (
                        <div className="mc-txt-max">
                            Max
                        </div>
            )
        }
           
        </>
        
    );
}

export default BtnUpGradeCard;