import avatar from "../../assets/images/hard/avatar-main.jpg";
import { formatNumberWithCommas } from "../FormatNumber/formatNumberWithCommas";

const UserHeaderCard = (props)=>{
    return(
        <div className="user-and-money">
            <div className="user">
                <img src={avatar} alt="avatar"/>
            </div>
            <div className="money">
                <div className="txt">
                    My Balance
                </div>
                <div className="currency">
                    {props.curBal ? formatNumberWithCommas(props.curBal) : "Loading..."}
                </div>
            </div>
        </div>
        
    );
}

export default UserHeaderCard;