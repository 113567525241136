import './Task.css';
import DgTask from "../../assets/images/hard/task-dg.png";
import dong from "../../assets/images/hard/dong.png";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { useCallback, useEffect, useState } from 'react';
import ButtonTask from '../../utils/Task/ButtonTask';
import ButtonTaskDaily from '../../utils/Task/ButtonTaskDaily';
import LoadingInApp from '../../utils/Loading/LoadingInApp';
import { formatNumberWithCommas } from '../../utils/FormatNumber/formatNumberWithCommas';

const Task = ()=>{
    const { user } = useUser();
    const navigate = useNavigate();
    const [listTaskFollow, setListTaskFollow] = useState([]);
    const [listTaskDaily, setListTaskDaily] = useState([]);
    const [isLoadingFollow, setIsLoadingFollow] = useState(true);
    const [isLoadingDaily, setIsLoadingDaily] = useState(true);
    const getListTaskFollow = useCallback(async (userId) => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
            const response = await fetch(`${baseUrl}/mc-task-follow/v1/list-task-for-user`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setListTaskFollow(data.list_task);
            setTimeout(() => {
                setIsLoadingFollow(false);
            }, 1000);
          } else {
            console.error('Error fetching task');
          }
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, []);

    const getListTaskDaily = useCallback(async (userId) => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
            const response = await fetch(`${baseUrl}/mc-task-daily/v1/list-task-daily-for-user`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setListTaskDaily(data.list_task);
            setTimeout(() => {
                setIsLoadingDaily(false);
            }, 1000);
          } else {
            console.error('Error fetching task');
          }
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, []);

    useEffect(() => {
        if (!user) {
          navigate('/');
        }else{
            getListTaskFollow(user.id);
            getListTaskDaily(user.id);
        }
      }, [user, navigate, getListTaskFollow, getListTaskDaily]);

    if (isLoadingFollow && isLoadingDaily) {
        return (
            <LoadingInApp />
        );
    }

    return(
        <div className="task_dragon_king">
            <div className="task_dragon_king_contain">
                <div className="mc-container-app">
                    <div className='title'>
                        Earn More Coins
                    </div>
                    <div className='Dg-Task'>
                        <img src={DgTask} alt="DgTask" />
                    </div>
                    <div className='list-task'>
                        <div className='list-taks-title'>
                            Daily tasks
                        </div>
                        <div className='list-task-cover'>
                            
                            {
                                listTaskDaily && listTaskDaily.map((item,index)=>(
                                <div key={index} className='item'>
                                    <div className='icon'>
                                        <img src={item.task_daily_image} alt="Icon Task" />
                                    </div>
                                    <div className='name-reward'>
                                        <div className='txt'>
                                            {item.task_daily_name}
                                        </div>
                                        <div className='nr-img-price'>
                                            <div className='img'>
                                                <img src={dong} alt="DgTask" />
                                            </div>
                                            <div className='pri'>
                                                <span>+ </span><span> {formatNumberWithCommas(item.task_daily_award)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonTaskDaily taskLink={item.task_daily_link} taskAdward={item.task_daily_award} 
                                        taskID={item.id}
                                        taskStatus={item.status} />
                                    
                                </div>

                                ))
                            }
                        </div>
                        
                    </div>
                    <div className='list-task'>
                        <div className='list-taks-title'>
                            Tasks list
                        </div>
                        <div className='list-task-cover'>
                            {
                                listTaskFollow && listTaskFollow.map((item,index)=>(
                                <div key={index} className='item'>
                                    <div className='icon'>
                                        <img src={item.task_follow_image} alt="Task Icon" />
                                    </div>
                                    <div className='name-reward'>
                                        <div className='txt'>
                                            {item.task_follow_name}
                                        </div>
                                        <div className='nr-img-price'>
                                            <div className='img'>
                                                <img src={dong} alt="DgTask" />
                                            </div>
                                            <div className='pri'>
                                                <span>+ </span><span> {formatNumberWithCommas(item.task_follow_award)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonTask taskLink={item.task_follow_link} taskAdward={item.task_follow_award} 
                                        taskID={item.id}
                                        taskStatus={item.status} />
                                </div>
                                ))
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Task;