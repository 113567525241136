import "./MoneyCurrent.css";
import dong from "../../assets/images/hard/dong.png";
import { formatNumberWithCommas } from "../FormatNumber/formatNumberWithCommas";

const MoneyCurrent = (props)=>{
    return(
        <div className="money_current">
            <div className="money_current_icon">
            <img src={dong} alt="Avatar" />
            </div>
            <div className="mc2_txt">
                {props.userInfo ? formatNumberWithCommas(props.userInfo.current_money) : "Loading..."}
            </div>
        </div>
    );
}

export default MoneyCurrent;