import "./UserHead.css";
import avatar from "../../assets/images/hard/avatar-main.jpg";
import dong from "../../assets/images/hard/dong.png";
import { formatNumber } from "../FormatNumber/FormatNumber";

const UserHead = (props)=>{
    return(
            <div className="user_head">
                <div className="us-row">
                    <div className="left">
                        <div className="left-img">
                            <img src={avatar} alt="Avatar" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            {props.userInfo ? props.userInfo.username: "Loading..."}
                        </div>
                        <div className="moneyhour">
                            <div className="icon-price">
                                <img src={dong} alt="Coin" />
                            </div>
                            <div className="moneyhour-txt">
                                <span>{props.userInfo ? formatNumber(props.userInfo.money_for_hour): "Loading..."} </span>
                                <span>/ hour</span>
                            </div>
                        </div>
                        <div className="level">
                            Lv.{props.level ? props.level: "Loading..."}
                        </div>
                        
                    </div>
                </div>
            </div>
        
    );
}

export default UserHead;