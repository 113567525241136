import "./PopupSuccess.css";
import DragonLe from "../../assets/images/hard/store-dragon.png";

const PopupSuccess = (props)=>{
    return(
        <div className="pop_success">
            <div className="mc-overlay-success">

            </div>
            <div className="mc-popup-success">
                <div className="ha-pop">
                    <img src={DragonLe} alt="coin" />
                </div>
                <div className="title-pop">
                    {props.textOnPop}
                </div>
                <button type="button" className="oke" onClick={props.closeSuccess}>OK</button>
            </div>
        </div>
    );
}

export default PopupSuccess;