import "./PopEarnMoneyOffline.css";
import DragonEarn from "../../assets/images/hard/dra-earn.png";
import Upgrade from "../../assets/images/hard/upgrade.png";
import dong from "../../assets/images/hard/dong.png";
import { Fragment } from "react/jsx-runtime";
import { formatNumberWithCommas } from "../FormatNumber/formatNumberWithCommas";

const PopEarnMoneyOffline = (props)=>{

    return(
        
        <Fragment>
            <div className="pop_earn_money_offline">
                <div className="upgrade_card_contain">
                    <div className="dr-on-card">
                        <img src={DragonEarn} alt="Dragon" />
                    </div>
                    <div className="title">
                        <div className="img-up">
                            <img src={Upgrade} alt="Upgrade" />
                        </div>
                        <div className="txt">
                            Amount Earned
                        </div>
                    </div>
                    <div className="desc">
                        Here's how much you earn
                    </div>
                    <div className="exchange">
                        <div className="ex-item ex-item-contain-color">
                            <div className="ex-item-contain">
                                <div className="ex-txt">
                                    Coin
                                </div>
                                <div className="card-ex-cover">
                                    <div className="card">
                                        <img src={dong} alt="CardUpg" />
                                    </div>
                                    <div className="numb">
                                        +{props.moneyEarn ? formatNumberWithCommas(props.moneyEarn) : 0}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-up">
                        <button type="button" onClick={props.closePopMoneyEarn}>OK</button>
                    </div>
                </div>
            </div>
            <div className="overlay-earn-ofline">

            </div>
        </Fragment>
        
    );
}

export default PopEarnMoneyOffline;