import DragonLe from "../../assets/images/hard/store-dragon.png";
import LoadingGift from "../../assets/images/hard/loading-gif.gif";
import './Store.css';
import { useCallback, useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import UserHeaderCard from "../../utils/UserHead/UserHeaderCard";
import ItemCard from "./ItemCard";
import LoadingInApp from "../../utils/Loading/LoadingInApp";

const Store = ()=>{
    const { user } = useUser();
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    const [userInfo, setUserInfo] = useState(null);
    const [userIdToBuy, setUserIdToBuy] = useState(null);
    const [listCard, setListCard] = useState(null);
    const [haveCard, setHaveCard] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [activeRank, setActiveRank] = useState(1);
    const [isLoadingCard, setIsLoadingCard] = useState(true);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [curBal, setCurBal] = useState(0);

    const getUserInfo = useCallback(async (userId) => {
        try {
            const response = await fetch(`${baseUrl}/mc-user/v1/get-user-info`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setUserInfo(data.user);
            setCurBal(data.user.current_money);
            setTimeout(() => {
                setIsLoadingUser(false);
            }, 1000);
          } else {
            console.error('Error fetching user');
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const infoCardForRankDefault = useCallback(async (userId, rankNum, rankTxt) => {
        try {
            const response = await fetch(`${baseUrl}/cards/v1/card-in-store`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                    'rank_num' : rankNum,
                    'rank_txt' : rankTxt
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setListCard(data.list_card);
            setLoadingCard(false);
            setHaveCard(false);
            setTimeout(() => {
                setIsLoadingCard(false);
            }, 1000);
          } else {
            setListCard([]);
            setLoadingCard(false);
            setHaveCard(true);
            setTimeout(() => {
                setIsLoadingCard(false);
            }, 1000);
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const infoCardForRank = useCallback(async (userId, rankNum, rankTxt) => {
        try {
            const response = await fetch(`${baseUrl}/cards/v1/card-in-store`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                    'rank_num' : rankNum,
                    'rank_txt' : rankTxt
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setListCard(data.list_card);
            setLoadingCard(false);
            setHaveCard(false);
          } else {
            setListCard([]);
            setLoadingCard(false);
            setHaveCard(true);
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const handlerLoadCardForRank = (rankNum, rankTxt)=>{
        if (user) {
            setActiveRank(rankNum);
            setLoadingCard(true); 
            setTimeout(() => {
                infoCardForRank(user.id, rankNum, rankTxt); 
            }, 500);
            
        }
    }
    const handleDataBalance = (balance) => {
        setCurBal(balance);
    };

    const handleDataFromFetch = (fetchedData) => {
        if (user) {
            setActiveRank(Number(fetchedData.rank_num));
            setLoadingCard(true); 
            setTimeout(() => {
                infoCardForRank(user.id, fetchedData.rank_num, fetchedData.rank_txt); 
            }, 500);
        }
    }

    useEffect(() => {
        if (user) {
            getUserInfo(user.user_id);
            infoCardForRankDefault(user.id, 1, "C");
            setUserIdToBuy(user.id);
        }
    }, [user, getUserInfo, infoCardForRankDefault]);

    if (isLoadingCard && isLoadingUser) {
        return (
            <LoadingInApp />
        );
    }


    return(
        <div className="mc-store">
            <div className="mc-store-contain">
                <div className="mc-container-app">
                    <div className="title">
                        Store
                    </div>
                    <UserHeaderCard userInfo={userInfo} curBal={curBal}/>
                    <div className="store-cover">
                        <div className="store-cat">
                            <div className={`item ${activeRank === 1 ? 'active' : ''}`} onClick={() => handlerLoadCardForRank(1, "C")}>
                                Rank C
                            </div>
                            <div className={`item ${activeRank === 2 ? 'active' : ''}`} onClick={() => handlerLoadCardForRank(2, "B")}>
                                Rank B
                            </div>
                            <div className={`item ${activeRank === 3 ? 'active' : ''}`} onClick={() => handlerLoadCardForRank(3, "A")}>
                                Rank A
                            </div>
                            <div className={`item ${activeRank === 4 ? 'active' : ''}`} onClick={() => handlerLoadCardForRank(4, "S")}>
                                Rank S
                            </div>
                            <div className={`item ${activeRank === 5 ? 'active' : ''}`} onClick={() => handlerLoadCardForRank(5, "SSS")}>
                                Rank SSS
                            </div>
                        </div>
                        <div className="list-card">
                            <ItemCard listCard={listCard} userID={userIdToBuy} onFetchData={handleDataFromFetch} onBalance={handleDataBalance} />
                            {
                                haveCard && (
                                    <div className="txt-no-card">No more cards to buy</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                loadingCard && (
                <div className="mc-loading">
                    <img src={LoadingGift} alt="coin" />
                </div>
                )
            }
            {
                loadingCard && (
                <div className="overlay-loading"></div>
                )
            }
            <div className="dra-sty-bonus">
                <img src={DragonLe} alt="coin" />
            </div>
        </div>
    );
}

export default Store;