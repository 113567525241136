import "./GuidePop.css";
import DragonLe from "../../assets/images/hard/store-dragon.png";

const GuidePop = (props)=>{
    return(
        <div className="mc_guide">
            <div className="mc-overlay-guide">

            </div>
            <div className="mc-popup-guide">
                <div className="ha-pop">
                    <img src={DragonLe} alt="coin" />
                </div>
                <div className="desc-pop">
                    <p>
                    GAME :  
                    </p>
                    <p>
                    - Click on the 'Play' button. 
                    </p>
                    <p>
                    - Two creatures will appear randomly: a dragon and a demon. 
                    </p>
                    <p>
                    - Clicking on the dragon will earn you points. 
                    </p>
                    <p>
                    - Clicking on the demon will deduct points.
                    </p>
                    <p>NOTE: </p>
                    <p>The points you earn are your in-game currency. Use this currency to purchase cards from the shop and upgrade the cards in your collection</p>
                </div>
                <button type="button" className="oke" onClick={props.closeGuide}>OK</button>
            </div>
        </div>
    );
}

export default GuidePop;