import dong from "../../assets/images/hard/dong.png";
import { formatNumber } from "../../utils/FormatNumber/FormatNumber";
import BuyCard from "./BuyCard";

const ItemCard = (props)=>{
    return(
        <>
        {
            props.listCard && props.listCard.map((item, index)=>(
                <div key={index} className="item-card">
                    <div className="card-rank">
                        <img src={item.frame} alt="RankC" />
                    </div>
                    <div className="child-on-card">
                        <div className="item-card-top">
                            <div className="dra">
                                <img src={item.image_card} alt="coin" />
                            </div>
                            <div className="right-ctop">
                                <div className="name-card">
                                    {item.name_card}
                                </div>
                                <div className="earn-per-hour">
                                    Earn per hour
                                </div>
                                <div className="txt-per">
                                    <img src={dong} alt="coin" />
                                    <div className="num">
                                        +{formatNumber(item.money_earn_of_card)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-card-bottom">
                            <BuyCard priceCard={item.price_card} cardID={item.id} userID={props.userID} onFetchData={props.onFetchData} onBalance={props.onBalance} />
                        </div>
                    </div>
                </div>
            ))
        }
        </>
        
        
        
    );
}

export default ItemCard;