import { useEffect, useState } from "react";
import Check from "../../assets/images/hard/check.png";
import { useUser } from "../../context/UserContext";

const ButtonTask = (props)=>{

    const { user } = useUser();

    const [isMaketask, setIsMaketask] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const handleButtonClick = (url)=>{
        if (!url) {
            console.error('URL is required to open a new tab.');
            return;
          }
          if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.openLink(props.taskLink);
          }
          setTimeout(() => {
            fetchClaimStatus();
          }, 1000);
    }
    const fetchClaimStatus = async () => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
          const response = await fetch(`${baseUrl}/mc-task-follow/v1/set-claim-status`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id':user.id,
                'task_follow_id':props.taskID
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY':appKey
            }
          });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const results = await response.json();
          if(results.status_data){
            setIsMaketask(true);
          }
        } catch (error) {
          console.log(error.message);
        }
    };
    const fetchClaimTask = async () => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
          const response = await fetch(`${baseUrl}/mc-task-follow/v1/claim-award`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id':user.id,
                'task_follow_award':props.taskAdward,
                'task_follow_id':props.taskID
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY':appKey
            }
          });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const results = await response.json();
          if(results.status_data){
            setIsComplete(true);
          }
        } catch (error) {
          console.log(error.message);
        }
    };
    const handleButtonClickClaim = ()=>{
        fetchClaimTask();
    }
    useEffect(() => {
        if(props.taskStatus === 'completed'){
            setIsComplete(true);
        }
        if(props.taskStatus === 'claim'){
            setIsMaketask(true);
        }
      }, [props.taskStatus]);
    return(
        <div className='st-btn-action'>
            {isComplete ? (
                <img src={Check} alt="Complete Icon" />
            ) : (
                isMaketask ? (
                    <button type='button' className='claim' onClick={handleButtonClickClaim}>
                        Claim
                    </button>
                ) : (
                    <button type='button' className='default' onClick={handleButtonClick}>
                        Go
                    </button>
                )
            )}
        </div>
        
        
    );
}

export default ButtonTask;