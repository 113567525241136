import UserHead from "../../utils/UserHead/UserHead";
import dong from "../../assets/images/hard/dong.png";
import shelf from "../../assets/images/hard/be.png";
import DragonGame from "../../assets/images/hard/dragon-game-2.png";
import DraStoreLe from "../../assets/images/hard/dr-store.png";
import TimeBg from "../../assets/images/hard/time.png";
import "./GameDragonKing.css"
import { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "../../context/UserContext";
import { formatNumberWithCommas } from "../../utils/FormatNumber/formatNumberWithCommas";
import DragonLe from "../../assets/images/hard/store-dragon.png";
import { useNavigate } from "react-router-dom";
import LoadingInApp from "../../utils/Loading/LoadingInApp";

const GameDragonKing = ()=>{
  const { user } = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [isPopSuccessGame, setIsPopSuccessGame] = useState(false);
  const hasRun = useRef(false);
  const navigate = useNavigate();

  const [slots, setSlots] = useState(Array(12).fill(null)); // 12 ô tròn
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30); // Thời gian ban đầu là 30 giây
  const [isGameOver, setIsGameOver] = useState(false); // Kiểm tra trạng thái game over
  const [level, setLevel] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  // Hàm tạo động vật ngẫu nhiên (5 chuột và 1 chó)
  const generateRandomAnimals = () => {
    let animals = Array(12).fill(null); // Mặc định tất cả ô trống
    let randomIndexes = [];
    while (randomIndexes.length < 6) {
      const randomIndex = Math.floor(Math.random() * 12);
      if (!randomIndexes.includes(randomIndex)) {
        randomIndexes.push(randomIndex);
      }
    }
    // Thêm 1 con chó và 5 con chuột vào 6 ô ngẫu nhiên
    animals[randomIndexes[0]] = "demon"; // Con chó
    randomIndexes.slice(1).forEach(index => animals[index] = "dragon"); // 5 con chuột
    setSlots(animals);
  };

  // Giảm thời gian mỗi giây
  useEffect(() => {
    if (isGameOver) return; // Nếu game đã kết thúc thì không giảm thời gian nữa
    if (!loading) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval); // Dừng thời gian khi hết giây
            setIsGameOver(true); // Kết thúc game
            setIsPopSuccessGame(true); // Show Popup Kết thúc game
          }
          return prevTime - 1;
        });
      }, 1000);
  
      return () => clearInterval(interval); // Dọn dẹp khi component bị unmount
    }
    
  }, [isGameOver, loading]);

  // Tạo động vật mới mỗi 1 giây nếu game chưa kết thúc
  useEffect(() => {
    if (isGameOver) return; // Nếu game đã kết thúc thì không tạo động vật nữa
    if (!loading) {
      const interval = setInterval(generateRandomAnimals, 1000);
      return () => clearInterval(interval); // Dọn dẹp khi component bị unmount
    }
    
  }, [isGameOver, loading]);

  // Hàm xử lý click vào động vật
  const handleClick = (animal, index) => {
    if (isGameOver) return; // Không cho bấm khi game đã kết thúc
    if (animal === "dragon") {
      setScore(score + 3600); // Cộng 2 điểm nếu bấm dragon
    } else if (animal === "demon") {
      if(score > 0){
        setScore(score - 500); // Trừ 1 điểm nếu bấm demon
      }else{
        setScore(0);
      }
      
    }
    // Ẩn động vật sau khi bấm
    const newSlots = [...slots];
    newSlots[index] = null; // Ẩn động vật ở vị trí đã click
    setSlots(newSlots);
  };

  const getUserInfo = useCallback(async (userId) => {
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    try {
        const response = await fetch(`${baseUrl}/mc-user/v1/get-user-info-and-level`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id' : userId,
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-API-KEY':appKey
            }
        });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      const data = await response.json();
      if (data.status_data) {
        setUserInfo(data.user);
        setLevel(data.level);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      } else {
        console.error('Error fetching');
      }
      
    } catch (error) {
        console.error('Error fetching user from database:', error);
    }
  }, []);

  const deTurnGame = useCallback(async (userId) => {
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    try {
        const response = await fetch(`${baseUrl}/game/v1/turn-game`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id' : userId,
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-API-KEY':appKey
            }
        });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      const data = await response.json();
      if (!data.status_data) {
        console.error('Error fetching');
      }
      
    } catch (error) {
        console.error('Error fetching user from database:', error);
    }
  }, []);

  useEffect(() => {
    if (user) {
        getUserInfo(user.id);
        if (!hasRun.current) {
          deTurnGame(user.id);
          hasRun.current = true;
        }
    }
    
    
  }, [user, getUserInfo, deTurnGame]);

  const addAwardGameToUser = useCallback(async (userId, scoreUser) => {
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    try {
        const response = await fetch(`${baseUrl}/game/v1/add-award`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id' : userId,
                'award_game' : scoreUser,
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-API-KEY':appKey
            }
        });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      const data = await response.json();
      if (data.status_data) {
        setIsPopSuccessGame(false);
        navigate('/');
      }else{
        setIsPopSuccessGame(false);
        navigate('/');
      }
      
    } catch (error) {
        console.error('Error fetching user from database:', error);
    }
  }, [navigate]);

  const handleOverGame = () =>{
    if (user) {
      if(!isClicked){
        setIsPopSuccessGame(false);
        setIsClicked(true);
        addAwardGameToUser(user.id, score);
      }
    }
  }

  if (loading) {
    return (
        <LoadingInApp />
    );
  }
    return(
        <div className="game_dragon_king">
            <div className="game_dragon_king_contain">
                <div className="mc-container-app">
                  <UserHead userInfo={userInfo} level={level}/>
                    <div className="money-in-game">
                        <div className="money-in-game-icon">
                            <img src={dong} alt="Avatar" />
                        </div>
                        <div className="mc2_txt">
                            {formatNumberWithCommas(score)}
                        </div>
                    </div>
                    <div className="game">

                    {slots.map((animal, index) => (
                        <div className="item-game" key={index}
                        onClick={() => animal && handleClick(animal, index)}>
                            <div className="dra">
                                {animal === "demon" ? <img src={DraStoreLe} alt="Dra" className="jello-horizontal" /> : animal === "dragon" ? <img src={DragonGame} alt="Dra" className="jello-horizontal" /> : ""}
                            </div>
                            <div className="shelf">
                                <img src={shelf} alt="shelf" />
                            </div>
                        </div>
                    ))}
                    </div>
                    <div className="time-game">
                        <img src={TimeBg} alt="Time" />
                        <div className="txt-time">
                            00:{timeLeft}
                        </div>
                    </div>
                </div>
            </div>
            {
              isPopSuccessGame && (
                <div className="mc-overlay-game-finish">

                </div>
              )
            }
            {
              isPopSuccessGame && (
                <div className="mc-popup-game-finish">
                  <div className="ha-pop">
                      <img src={DragonLe} alt="coin" />
                  </div>
                  <div className="title-pop">
                      Game over
                  </div>
                  <div className="money-earn">
                      + {formatNumberWithCommas(score)}
                  </div>
                  <button type="button" className="oke" onClick={handleOverGame}>OK</button>
              </div>
              )
            }
            
            
        </div>
    );
}

export default GameDragonKing;