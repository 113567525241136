import { useEffect, useRef, useState } from "react";
import Check from "../../assets/images/hard/check.png";
import { useUser } from "../../context/UserContext";
import DragonLe from "../../assets/images/hard/store-dragon.png";
import PopupSuccess from "../PopupSuccess/PopupSuccess";


const ButtonTaskDaily = (props)=>{

    const { user } = useUser();

    const [isMaketask, setIsMaketask] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isPopVerify, setIsPopVerify] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isNotKey, setIsNotKey] = useState(false);
    const verifyRef = useRef();
    const handleButtonClick = (url)=>{
        if (!url) {
            console.error('URL is required to open a new tab.');
            return;
          }
          if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.openLink(props.taskLink);
          }
          setTimeout(() => {
            setIsMaketask(true);
          }, 1000);
    }
    
    const handleButtonClickVerify = ()=>{
        setIsPopVerify(true);
    }
    const closePopSuccess = ()=>{
        setIsSuccess(false);
    }
    const fetchVerifyTask = async (inputValue) => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
          const response = await fetch(`${baseUrl}/mc-task-daily/v1/verify-key`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id':user.id,
                'task_daily_award':props.taskAdward,
                'task_daily_key':inputValue,
                'task_daily_id':props.taskID
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY':appKey
            }
          });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const results = await response.json();
          if(results.status_data){
            setIsSuccess(true);
            setIsPopVerify(false);
            setIsComplete(true);
            verifyRef.current.value = "";
          }else{
            setIsNotKey(true);
          }
        } catch (error) {
          console.log(error.message);
        }
    };
    const handleVerify = () => {
        const inputValue = verifyRef.current.value.trim();
        if (inputValue) {
            fetchVerifyTask(inputValue);
        } else {
            setIsNotKey(true);
        }
      };
    const closeVerify = ()=>{
        setIsPopVerify(false);
    }
    useEffect(() => {
        if(props.taskStatus === 'completed'){
            setIsComplete(true);
        }
      }, [props.taskStatus]);
    return(
        <div>
            <div className='st-btn-action'>
                {isComplete ? (
                    <img src={Check} alt="Complete Icon" />
                ) : (
                    isMaketask ? (
                        <button type='button' className='claim' onClick={handleButtonClickVerify}>
                            Verify
                        </button>
                    ) : (
                        <button type='button' className='default' onClick={handleButtonClick}>
                            Go
                        </button>
                    )
                )}
            </div>
            {
                isPopVerify && (
                    <div className="overlay-task">

                    </div>
                )
            }
            {
                isPopVerify && (
                    <div className="popup-verify-task">
                        <div className="ha-pop">
                            <img src={DragonLe} alt="coin" />
                        </div>
                        <div className="title-pop">
                            Enter key
                        </div>
                        <div className="inp-key">
                            <input type="text" ref={verifyRef} placeholder="Enter key to Verify" />
                        </div>
                        {
                            isNotKey && (
                            <div className="key-fail">
                                Key is incorrect
                            </div>
                            )
                        }
                        <button type="button" className="verify" onClick={handleVerify}>Verify</button>
                        <button type="button" className="close-ve" onClick={closeVerify}>Close</button>
                    </div>
                )
            }
            {
                isSuccess && <PopupSuccess textOnPop={'You have successfully verified !'} closeSuccess={closePopSuccess} />
            }
            
        </div>
        
        
        
    );
}

export default ButtonTaskDaily;