import "./NoticeWhenTDesktop.css";

const NoticeWhenTDesktop = ()=>{
    return(
        <div className="notice_when_tdesktop">
            Please access this application on your phone!
        </div>
    );
}

export default NoticeWhenTDesktop;