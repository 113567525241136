export const formatNumber = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'B'; // Tỷ (Billion)
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M'; // Triệu (Million)
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K'; // Nghìn (Thousand)
    } else {
        return num.toString(); // Số nhỏ hơn 1000
    }
};