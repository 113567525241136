import "./Airdrop.css";
import DgAir from "../../assets/images/hard/air-dg.png";
import Baket from "../../assets/images/hard/icon-air.png";
import { TonConnectButton, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import LoadingInApp from "../../utils/Loading/LoadingInApp";

const Airdrop = ()=>{
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    const [isConnectWallet, setIsConnectWallet] = useState(false);
    const { user } = useUser();
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    const [isLoading, setIsLoading] = useState(true);
    const [isTransaction, setIsTransaction] = useState(false);

    const addWalletInfo = useCallback(async (userId, rawAddress, walletName) => {
        try {
            const response = await fetch(`${baseUrl}/wallet/v1/add-wallet-address`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                    'raw_address' : rawAddress,
                    'wallet_name' : walletName
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setIsConnectWallet(true);
            setIsLoading(false);
          } else {
            console.error('Error fetching user');
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const addFriendlyWalletAddress = useCallback(async (userId, friendlyAddress) => {
        try {
            const response = await fetch(`${baseUrl}/wallet/v1/add-friendly-wallet-address`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                    'user_friendly_address' : friendlyAddress
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setIsConnectWallet(true);
          } else {
            console.error('Error fetching user');
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    const disconnectWallet = useCallback(async (userId) => {
        try {
            const response = await fetch(`${baseUrl}/wallet/v1/disconnect-wallet`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setIsConnectWallet(false);
            setIsLoading(false); 
          } else {
            console.error('Error fetching user');
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    useEffect(() => {
        if (userFriendlyAddress) {
            addFriendlyWalletAddress(user.id, userFriendlyAddress);
            setIsConnectWallet(true);
        }
        if (user) {
            setTimeout(() => {
                setIsLoading(false); 
            }, 500);
            
            const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
              if (wallet) {
                setTimeout(() => {
                    setIsLoading(true); 
                }, 100);
                addWalletInfo(user.id, wallet.account.address, wallet.name);
              } else {
                setTimeout(() => {
                    setIsLoading(true); 
                }, 100);
                disconnectWallet(user.id);
                setIsConnectWallet(false);
              }
            });
            return () => unsubscribe();
        }
      }, [tonConnectUI, userFriendlyAddress]);

    const handleSendTransaction = async () => {
        try {
          await tonConnectUI.sendTransaction({
            validUntil: Math.floor(Date.now() / 1000) + 60,
            messages: [
              {
                address: "UQDEZcSdE_NFG97E0YJ1x00xIlBVG2s2PkfpaUDHbUMdjlcw",
                amount: "300000000",
              },
            ],
          });
          setIsTransaction(true);
          await sendDataToServer(user.id);
        } catch (error) {
          console.error('Error sending transaction:', error);
        }
      };

    const sendDataToServer = async (userId) => {
        try {
        const response = await fetch(`${baseUrl}/wallet/v1/status-transaction`, {
            method: 'POST',
            body:JSON.stringify({
                'user_id' : userId
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY':appKey
            }
        });

        if (!response.ok) {
            throw new Error('Something went wrong!');
        }

        const data = await response.json();
        if (data.status_data) {
            setIsTransaction(true);
        }
        } catch (error) {
            console.error('Error sending data to server:', error);
        }
    };

    const checkTransaction = useCallback(async (userId) => {
        try {
            const response = await fetch(`${baseUrl}/wallet/v1/check-transaction`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setIsTransaction(true);
          } else {
            console.error('Error fetching user');
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, [baseUrl, appKey]);

    useEffect(() => {
        if (user) {
            checkTransaction(user.id);
        }
      }, []);

    if (isLoading) {
        return (
            <LoadingInApp />
        );
    }
    
    return(
        <div className="airdrop_dg">
            <div className="airdrop_dg_contain">
                <div className="mc-container-app">
                    <div className="dg-air">
                        <img src={DgAir} alt="Airdrop" />
                    </div>
                    <div className="air-title">
                        Airdrop Tasks
                    </div>
                    <div className="air-desc">
                        Ready to join the airdrop? Simply complete the tasks below to get started.
                    </div>
                    <div className="task-lists-titl">
                        Task List
                    </div>
                    <div className="task-list-cover">
                        <div className="btn-con">
                            <TonConnectButton />
                        </div>
                        {
                            isConnectWallet ? (
                                <div className="txt-bot-connect-ok">
                                    You have connected to the wallet.
                                </div>
                            ) :
                            (
                                <div className="txt-bot-connect">
                                    Connect to your wallet.
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="task-lists">
                        {
                            isConnectWallet ? (
                                <button type="button" className="item" onClick={handleSendTransaction}>
                                    <div className="icon">
                                        <img src={Baket} alt="Baket" />
                                    </div>
                                    <div className="txt">
                                        Make TON transaction
                                    </div>
                                    <div className="arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M6.87504 16.875C6.7152 16.875 6.5552 16.8139 6.43316 16.6919C6.18895 16.4476 6.18895 16.0522 6.43316 15.8081L12.2413 9.99998L6.43316 4.19186C6.18895 3.94764 6.18895 3.55217 6.43316 3.30811C6.67738 3.06404 7.07285 3.06389 7.31691 3.30811L13.5669 9.55811C13.8111 9.80232 13.8111 10.1978 13.5669 10.4419L7.31691 16.6919C7.19488 16.8139 7.03488 16.875 6.87504 16.875Z" fill="white"/>
                                        </svg>
                                    </div>
                                </button>
                            ) :
                            (
                                <button type="button" className="item item-disable">
                                    <div className="icon">
                                        <img src={Baket} alt="Baket" />
                                    </div>
                                    <div className="txt">
                                        Make TON transaction
                                    </div>
                                    <div className="arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M6.87504 16.875C6.7152 16.875 6.5552 16.8139 6.43316 16.6919C6.18895 16.4476 6.18895 16.0522 6.43316 15.8081L12.2413 9.99998L6.43316 4.19186C6.18895 3.94764 6.18895 3.55217 6.43316 3.30811C6.67738 3.06404 7.07285 3.06389 7.31691 3.30811L13.5669 9.55811C13.8111 9.80232 13.8111 10.1978 13.5669 10.4419L7.31691 16.6919C7.19488 16.8139 7.03488 16.875 6.87504 16.875Z" fill="white"/>
                                        </svg>
                                    </div>
                                </button>
                            )
                        }
                        {
                            isTransaction && (
                                <div className="status-transaction">
                                    You have completed the transaction.
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Airdrop;