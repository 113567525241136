import "./Rank.css";
import avatar from "../../assets/images/hard/avatar-main.jpg";
import dong from "../../assets/images/hard/dong.png";
import Rank2 from "../../assets/images/hard/rank-2.png";
import Rank1 from "../../assets/images/hard/rank-1.png";
import Rank3 from "../../assets/images/hard/rank-3.png";
import VIP from "../../assets/images/hard/vip.png";
import { useNavigate } from 'react-router-dom';
import { useUser } from "../../context/UserContext";
import { useCallback, useEffect, useState } from "react";
import { formatNumber } from "../../utils/FormatNumber/FormatNumber";
import LoadingInApp from "../../utils/Loading/LoadingInApp";

const Rank = ()=>{
    const { user } = useUser();
    const navigate = useNavigate();
    const [listRank, setListRank] = useState([]);
    const [listRankNoTop, setListRankNoTop] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [positionRankUser, setPositionRankUser] = useState(null);

    const fetchTopUsers = async () => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
      
        try {
          const response = await fetch(`${baseUrl}/mc-user/v1/rank-user`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-API-KEY':appKey
            },
          });
      
          const data = await response.json();
          if (data.status_data) {
            setListRank(data.user_data);
            setListRankNoTop(data.user_data.slice(3));
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            
          } else {
            console.error('Error fetching rank');
          }
        } catch (error) {
          console.error('Error fetching top users:', error);
          return [];
        }
    };
    const getCurrentUserInfo = useCallback(async (userId) => {
        const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
        const appKey = process.env.REACT_APP_MC_AKI_MAIN;
        try {
            const response = await fetch(`${baseUrl}/mc-user/v1/position-rank-user`, {
                method: 'POST',
                body:JSON.stringify({
                    'user_id' : userId,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  'X-API-KEY':appKey
                }
            });
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
          const data = await response.json();
          if (data.status_data) {
            setCurrentUser(data.user_data);
            if(data.position > 1000){
                setPositionRankUser('1K+');
            }else{
                setPositionRankUser(data.position);
            }
            
          } else {
            console.error('Error fetching user:', data.message);
          }
          
        } catch (error) {
            console.error('Error fetching user from database:', error);
        }
    }, []);

    useEffect(() => {
        if (!user) {
          navigate('/');
        }else{
            fetchTopUsers();
            getCurrentUserInfo(user.user_id);
        }
      }, [user, navigate, getCurrentUserInfo]);

    if (loading) {
        return (
            <LoadingInApp />
        );
    }
    return(
        <div className="rank_dg">
            <div className="rank_dg_contain">
                <div className="mc-container-app">
                    <div className="title-rank">
                        Leaderboard
                    </div>
                    <div className="top-rank">
                        {
                            listRank.length > 0 && (
                            <div className="item item-rank-2">
                                <div className="avatar">
                                    <img src={avatar} alt="Avatar" />
                                </div>
                                <div className="name">
                                    {listRank[1].username}
                                </div>
                                <div className="total-coin">
                                    <div className="icon">
                                        <img src={dong} alt="Avatar" />
                                    </div>
                                    <div className="txt">
                                        {formatNumber(listRank[1].money_for_hour)}
                                    </div>
                                </div>
                                <div className="bes">
                                    <img src={Rank2} alt="Rank2" />
                                </div>
                            </div>
                            )
                        }
                        

                        {
                            listRank.length > 0 && (
                            <div className="item item-rank-1">
                                <div className="avatar">
                                    <div className="vip">
                                        <img src={VIP} alt="VIP" />
                                    </div>
                                    <img src={avatar} alt="Avatar" />
                                </div>
                                <div className="name">
                                    {listRank[0].username}
                                </div>
                                <div className="total-coin">
                                    <div className="icon">
                                        <img src={dong} alt="Avatar" />
                                    </div>
                                    <div className="txt">
                                        {formatNumber(listRank[0].money_for_hour)}
                                    </div>
                                </div>
                                <div className="bes">
                                    <img src={Rank1} alt="Rank1" />
                                </div>
                            </div>
                            )
                        }
                        
                        {
                            listRank.length > 0 && (
                                <div className="item item-rank-3">
                                    <div className="avatar">
                                        <img src={avatar} alt="Avatar" />
                                    </div>
                                    <div className="name">
                                        {listRank[2].username}
                                    </div>
                                    <div className="total-coin">
                                        <div className="icon">
                                            <img src={dong} alt="Avatar" />
                                        </div>
                                        <div className="txt">
                                            {formatNumber(listRank[2].money_for_hour)}
                                        </div>
                                    </div>
                                    <div className="bes">
                                        <img src={Rank3} alt="Rank3" />
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="ten-rank">
                        {
                           listRankNoTop.length > 0 && listRankNoTop.map((item, index)=>(
                                <div key={index} className="item-ten-rank">
                                    <div className="stt">
                                        {index + 4}
                                    </div>
                                    <div className="avatar">
                                        <img src={avatar} alt="Avatar" />
                                    </div>
                                    <div className="name-coin">
                                        <div className="name">
                                            {item.username}
                                        </div>
                                        <div className="coin-total">
                                            <div className="icon">
                                                <img src={dong} alt="Avatar" />
                                            </div>
                                            <div className="txt">
                                                {formatNumber(item.money_for_hour)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            currentUser && (
                                <div className="item-ten-rank-your">
                                    <div className="stt">
                                    {positionRankUser}
                                    </div>
                                    <div className="avatar">
                                        <img src={avatar} alt="Avatar" />
                                    </div>
                                    <div className="name-coin">
                                        <div className="name">
                                            {currentUser.username} (YOU)
                                        </div>
                                        <div className="coin-total">
                                            <div className="icon">
                                                <img src={dong} alt="Avatar" />
                                            </div>
                                            <div className="txt">
                                                {formatNumber(currentUser.money_for_hour)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rank;