import dong from "../../assets/images/hard/dong.png";
import { formatNumber } from "../../utils/FormatNumber/FormatNumber";
import BtnUpGradeCard from "./BtnUpGradeCard";

const ItemCardCollection = (props)=>{

    return(
        <>
        {
            props.listCard && props.listCard.map((item, index)=>(
                <div key={index} className="item-card">
                    <div className="card-rank">
                        <img src={item.frame} alt="Rank" />
                    </div>
                    <div className="child-on-card">
                        <div className="item-card-top">
                            <div className="dra">
                                <img src={item.image_card} alt="coin" />
                            </div>
                            <div className="right-ctop">
                                <div className="name-card">
                                    {item.name_card}
                                </div>
                                <div className="earn-per-hour">
                                    Earn per hour
                                </div>
                                <div className="txt-per">
                                    <img src={dong} alt="coin" />
                                    <div className="num">
                                        +{formatNumber(item.money_current_earn)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-card-bottom">
                            <div className="lvel-card">
                                <span>Lv.</span>
                                <span>{item.current_level}</span>
                            </div>
                            <div className="btn-cover">
                                <BtnUpGradeCard infoCard={item} userID={props.userID} onFetchData={props.onFetchData} onBalance={props.onBalance}/>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
        </>
        
    );
}

export default ItemCardCollection;