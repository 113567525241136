import "./BottomBar.css";
import myCollection from '../assets/images/hard/joystick.png';
import Store from '../assets/images/hard/Shop.png';
import Friend from '../assets/images/hard/Two-Person.png';
import Task from '../assets/images/hard/Location.png';
import Egg from '../assets/images/egg.png';
import bghome from '../assets/images/hard/home.png';
import { NavLink } from 'react-router-dom';

const BottomBar = ()=>{
    return (
        <div className="bottom_bar">
            <div className="bt-row">
                <NavLink  className={({ isActive }) => `${isActive ? "active" : ""} item-bar`} to={'/collection'}>
                    <div className="icon">
                        <img src={myCollection} alt="Collection" />
                    </div>
                    <div className="txt">
                        Collection
                    </div>
                </NavLink>
                <NavLink  className={({ isActive }) => `${isActive ? "active" : ""} item-bar`} to={'/store'}>
                    <div className="icon">
                    <img src={Store} alt="Store" />
                    </div>
                    <div className="txt">
                        Store
                    </div>
                </NavLink>
                <NavLink  className={({ isActive }) => `${isActive ? "active" : ""} item-bar item-bar-center`} to={'/'}>
                    <div className="icon icon-bar-center">
                        <div className="icon-bar-center-cover">
                            <img src={bghome} alt="Home" />
                            <div className="ic-small-bar">
                                <img src={Egg} alt="Home" />
                            </div>
                        </div>
                    </div>
                    <div className="txt txt-bar-center">
                        Home
                    </div>
                </NavLink>
                <NavLink  className={({ isActive }) => `${isActive ? "active" : ""} item-bar`} to={'/invite-friend'}>
                    <div className="icon">
                        <img src={Friend} alt="Friend" />
                    </div>
                    <div className="txt">
                       Friend
                    </div>
                </NavLink>
                <NavLink  className={({ isActive }) => `${isActive ? "active" : ""} item-bar`} to={'/task'}>
                    <div className="icon">
                    <img src={Task} alt="Task" />
                    </div>
                    <div className="txt">
                        Task
                    </div>
                </NavLink>
            </div>
        </div>
    );
}

export default BottomBar;