
import BottomBar from './layout/bottombar';
import GameDragonKing from './pages/Game/GameDragonKing';
import HomeDragonKing from './pages/Home/HomeDragonKing';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Friend from './pages/Friend/Friend';
import Store from './pages/Store/Store';
import Task from './pages/Task/Task';
import Rank from './pages/Rank/Rank';
import Airdrop from './pages/Airdrop/Airdrop';
import MyCollection from './pages/MyCollection/MyCollection';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUser } from './context/UserContext';
import NotFound from './pages/404/NotFound';
import LoadingStart from './utils/Loading/LoadingStart';
import PopEarnMoneyOffline from './utils/PopEarnMoneyOffline/PopEarnMoneyOffline';
import NoticeWhenTDesktop from './utils/NoticeWhenTDesktop/NoticeWhenTDesktop';

function App() {
  const { user, setUser } = useUser(); // Lấy Context
  const hasRun = useRef(false);
  const [loading, setLoading] = useState(true);
  const [moneyEarn, setMoneyEarn] = useState(0);
  const [isShowPopEarn, setIsShowPopEarn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const fetchUserFromDatabase = useCallback(async (user_id, username, language_code, startapp, setUser) => {
    const baseUrl = process.env.REACT_APP_MC_AI_MAIN_L;
    const appKey = process.env.REACT_APP_MC_AKI_MAIN;
    const time_login = new Date().toISOString();
  
    try {
      const response = await fetch(`${baseUrl}/mc-user/v1/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY':appKey
        },
        body: JSON.stringify({
          user_id,
          username,
          language_code,
          startapp,
          time_login,
        }),
      });
  
      const data = await response.json();
  
      if (data.status_data) {
        setUser(data.user); // Lưu thông tin người dùng vào Context
        if(data.user.money_earn > 0){
          setMoneyEarn(data.user.money_earn);
          setIsShowPopEarn(true);
        }
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        console.error('Error fetching user:', data.message);
      }
    } catch (error) {
      console.error('Error fetching user from database:', error);
    }
  }, []);

  useEffect(() => {
    const user_id = window.Telegram.WebApp?.initDataUnsafe?.user?.id;
    const username = window.Telegram.WebApp?.initDataUnsafe?.user?.username;
    const language_code = window.Telegram.WebApp?.initDataUnsafe?.user?.language_code;
    let startapp = "";
    if (user_id) {
      if(window.Telegram.WebApp.initDataUnsafe.start_param){
        if(window.Telegram.WebApp.initDataUnsafe.start_param === user_id){
          startapp = "";
        }else{
          startapp = window.Telegram.WebApp.initDataUnsafe.start_param;
        }
      }else{
        startapp = "";
      }
      if (!hasRun.current) {
        fetchUserFromDatabase(user_id, username, language_code, startapp, setUser);
        hasRun.current = true;
      }
      
    }
    if (window.Telegram.WebApp.platform) {
      setIsMobile(window.Telegram.WebApp.platform);
    } else {
      setIsMobile(window.Telegram.WebApp.platform);
    }
    const preventScrollOutside = (event) => {
        const appElement = document.getElementById("app-container");
        if (appElement && !appElement.contains(event.target)) {
            event.preventDefault(); // Ngăn Telegram nhận sự kiện vuốt
        }
    };
    // Gắn sự kiện touchmove để ngăn cuộn ngoài ứng dụng
    document.addEventListener("touchmove", preventScrollOutside, { passive: false });
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.expand(); // Mở rộng ứng dụng toàn màn hình
    }
    return () => {
        document.removeEventListener("touchmove", preventScrollOutside);
    };
    
  }, [setUser]);

  const closePopMoneyEarn = ()=>{
    setIsShowPopEarn(false);
  }

  if (isMobile === 'tdesktop') {
    return (
      <NoticeWhenTDesktop />
    );
  }else{
    if (loading) {
      return (
          <LoadingStart />
      );
    }
  }

  if (!loading && isShowPopEarn) {
    return (
        <PopEarnMoneyOffline moneyEarn={moneyEarn} closePopMoneyEarn={closePopMoneyEarn} />
    );
  }

  return (
    <Router>
      <main className='main-contain' id="app-container"
            style={{
                height: "100vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch", // Hỗ trợ mượt cho iOS
            }}>
        <Routes>
          <Route path="/" element={<HomeDragonKing />} />
          <Route path="/game" element={<GameDragonKing />} />
          <Route path="/invite-friend" element={<Friend />} />
          <Route path="/store" element={<Store />} />
          <Route path="/task" element={<Task />} />
          <Route path="/rank" element={<Rank />} />
          <Route path="/airdrop" element={<Airdrop />} />
          <Route path="/collection" element={<MyCollection />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <BottomBar />
      </main>
      
  </Router>
  );
}

export default App;
